import React, { Component } from 'react'
import { graphql } from 'gatsby';
import Layout from './layout';

// below is from Scott in the tutorial
// in section "#13 Page Query vs Static Query"

// Static Query
// can be used anywhere, doesn't accept variable, can't use context

// Page Query
// must be used on pages

export default class postLayout extends Component {
  render() {
    const { markdownRemark } = this.props.data;
    const { location } = this.props;
    return (
      <Layout location={location}>
        <h1>{markdownRemark.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{
          __html: markdownRemark.html
        }} />
      </Layout>
    )
  }
}

// in GraphQL you can pass parameters in your query
// which is what ($slug: String!) is below
// $slug is the parameter and String! is saying it is type = string 
// the ! means is it required 
// so that gets used in this below it:
// slug: {
//   eq: $slug
// }
// (note eq: is a Gatsby thing, not a React or GraphQL thing)
// this works because of this that we included in gatsby-node.js
// where we created the post pages 
// around line 79:
// context: {
//   slug: node.frontmatter.slug,
// }

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(frontmatter: {
      slug: {
        eq: $slug
      }
    }) {
      html
      frontmatter {
        title
        date
        slug
      }
    }
  }
`;